import ManropeMediumOTF from '../../public/fonts/manrope-medium.otf';
import ManropeMediumWOFF from '../../public/fonts/manrope-medium.woff';
import ManropeMediumWOFF2 from '../../public/fonts/manrope-medium.woff2';
import ManropeSemiboldOTF from '../../public/fonts/manrope-semibold.otf';
import ManropeSemiboldWOFF from '../../public/fonts/manrope-semibold.woff';
import ManropeSemiboldWOFF2 from '../../public/fonts/manrope-semibold.woff2';
import ManropeBoldOTF from '../../public/fonts/manrope-bold.otf';
import ManropeBoldWOFF from '../../public/fonts/manrope-bold.woff';
import ManropeBoldWOFF2 from '../../public/fonts/manrope-bold.woff2';
import calendar from '../../public/images/calendar.png';
import { createGlobalStyle } from 'styled-components'
import device from "./devices";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Manrope-Medium';
    font-style: normal;
    src: url(${ManropeMediumOTF}) format('opentype'),
         url(${ManropeMediumWOFF}) format('woff'),
         url(${ManropeMediumWOFF2}) format('woff2');
  }

  @font-face {
    font-family: 'Manrope-Semibold';
    font-style: normal;
    src: url(${ManropeSemiboldOTF}) format('opentype'),
         url(${ManropeSemiboldWOFF}) format('woff'),
         url(${ManropeSemiboldWOFF2}) format('woff2');
  }

  @font-face {
    font-family: 'Manrope-Bold';
    font-style: normal;
    src: url(${ManropeBoldOTF}) format('opentype'),
         url(${ManropeBoldWOFF}) format('woff'),
         url(${ManropeBoldWOFF2}) format('woff2');
  }
  :root {
    --processes-map-table-gap: 1.5rem;
    --processes-map-side-col-width: 80px;
    --box-border-color:#ddd;
    --error-color:#ec1e1e;
  }
  html {
    font-size: 14px;
  }
  *, *:after, *:before {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    background-color: #f3f5f9;
  }
 
  h1, h2 {
    font-family: Manrope-Semibold, sans-serif;
    font-size: 1.4rem;
    color: #2e2925;
    margin: 12px 0;
  }

  h2 { font-size: 1.2rem; }
  h3 { font-size: 1rem; }
  h4 { font-size: 0.8rem; }
  h5 { font-size: 0.7rem; }
  h6 { font-size: 0.6rem; }
  img {
    max-width:100%;
  }
  label {
    font-family: Manrope-Semibold, sans-serif;
    color: #545d6a;
    font-size: 0.875rem;
    margin: 24px 0 8px;
  }

  input {
    padding: 14px 10px;
    font-size: 0.875rem;
    border: 1px solid #545d6a;
  }

  button {
    cursor: pointer;
    outline: none;
  }

  p {
    font-family: Manrope-Semibold, sans-serif;
    font-size: 0.875rem;
    margin: 0;
    &.gray {
      color: #545d6a;
    }
  }

  a {
    font-family: Manrope-bold, sans-serif;
    font-size: 0.8745rem;
    color: #2e2925;
    text-decoration: inherit;
    cursor: pointer;
    &:hover, &:active, &:focus {
      text-decoration:none;
    }
  }
  b { font-family: 'Manrope-Bold', sans-serif; }
  .font-medium {
    font-family: Manrope-Medium, sans-serif;
  }
  .text-center {
    text-align:center;
  }
  
  /* //BREADCRUMBS */
  .breadcrumbs {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }
  .breadcrumbs__inner {
    color: #aaa;
  }
  .breadcrumbs__crumb:hover {
    text-decoration: none;
    color:#333;
  }
  .breadcrumbs__crumb:focus {
    text-decoration: none;
    color:#333;
  }
  
  .breadcrumbs__crumb, .breadcrumbs__separator {
    color: #aaa;
    //margin-right: .5rem;
  }
  .breadcrumbs__separator {

    margin-right: .5rem;
    /* //separator sign hack      */
    display: inline-block;
    visibility: hidden;
    :after {
      content:'›';
      font-family: Arial;
      font-size: 1rem;
      visibility: visible;
    }
  }
  
  [type="date"] {
    background:#fff url(${calendar})  94% 50% no-repeat ;
  }
  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  [type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    padding: 5px;
  }
  .margin-top-05 {
    margin-top:.5rem;
  }
  .margin-top-1 {
    margin-top:1rem;
  }
  .margin-top-2 {
    margin-top:2rem;
  }
  .margin-top-3 {
    margin-top:3rem;
  }
  .margin-bottom-05 {
    margin-bottom:.5rem;
  }
  .margin-bottom-1 {
    margin-bottom:1rem;
  }
  .margin-bottom-2 {
    margin-bottom:2rem;
  }
  .margin-bottom-3 {
    margin-bottom:3rem;
  }
  .margin-left-1 {
    margin-left:1rem;
  }

  .show-for-small {
    @media ${device.tablet} {
      display: none !important;
    }
  }
  .hide-for-small {
    @media ${device.lessTablet} {
      display: none !important;
    }
  }
`;